:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom); /* THIS ONE GETS US THE HOME BAR HEIGHT */
  --sal: env(safe-area-inset-left);
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'din-2014';
}

.App>nav {
  flex: 1 0 auto;
}

.App>div {
  flex: 1 1 100%;
}

body {
  margin: 0;
  font-family: din-2014;
  font-size: 16px;
  background: #222;
  color: #fff;
  width: 100vw;
  overflow: hidden;
  border-top: 3px solid #FC3027;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

nav {
  display: flex;
  align-items: center;
  position: relative;
}

nav::before {
  content: '';
  position: absolute;
  left: 0;
  height: 150%;
  width: 100%;
  top: 0;
  background: linear-gradient(to bottom, black, transparent);
  z-index: -1;
  opacity: 0.51;
  background-blend-mode: multiply;
  pointer-events: none;
}

.caseStudyMap {
  position: fixed;
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
  padding: 30px;
  padding-top: 0;
  background: #fff;
  z-index: 100000;
  transform: translateX(0%);
  transition: 0.25s transform;
  text-align: left;
  border-top: 3px solid #fff;
  display: flex;
  flex-direction: column;
}
.caseStudyMapHeader {
  height: 131px;
  display: flex;
  align-items: center;
  flex: 131px 0 auto;
}
.map .caseStudyMapBody {
  height: 100%;
  position: relative;
}
.caseStudyMapBody {
  flex: 1 1 auto;
}
.caseStudyMapHeader button {
  font-family: din-2014;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  position: relative;
  padding-left: 30px;
}
.caseStudyMapHeader button i {
  font-size: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid;
  line-height: 18px;
  border-radius: 16px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  transition: 0.25s transform;
}
.caseStudyMapHeader button.open i {
  transform: translateY(-50%) rotate(180deg);
  transition: 0.25s transform;
}
.caseStudyMap.closed {
  transition: 0.25s transform;
  transform: translateX(100%);
}
.caseStudyMap.closed.peek {
  transition: 0.25s transform;
  transform: translateX(90%);
}
nav ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style-type: none;
  margin-left: 0;
  margin-right: 0;
  transition: 0.25s margin-right;
}
.withMap nav ul {
  margin-right: 100px;
  transition: 0.25s margin-right;
}

a {
  color: #fff;
}

.team {
  display: flex;
}

.App {
}

.activeMemberHeader {
  display: flex;
  align-items: center;
}

.activeMemberHeaderLeft {
  flex: 2 0 0;
}

.activeMemberHeaderRight {
  flex: 6 1 0;
}

.headshotWrap {
  flex: 1 0 0;
  height: 24vh;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.teamNav {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%;
  justify-content: space-between;
  flex: 1;
}

.teamNavIndicatorWrap {
  flex: 1;
  align-items: center;
  display: flex;
}

.teamNavIndicator {
  width: 80px;
  height: 80px;
  position: relative;
  flex: 1;
  border-radius: 50%;
  overflow: hidden;
  background: #4E4E4E;
  border: 2px solid #4e4e4e;
  transition: 0.25s all;
  transform: scale(0.25);
  cursor: pointer;
  
}

.teamNavIndicator.active {
  border-color: #FC3027;
  transition: 0.25s all;
  transform: scale(1.0);
}

.teamNavIndicator img {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  opacity: 1.0;
  transition: 0.25s opacity;
  transform: translateX(-50%);
}

.teamNavIndicator:not(.active) img {
  opacity: 0;
  transition: 0.25s opacity;
}

.activeTeamMember {
  flex: 9;
}

.activeMemberName, .activeMemberContact, .activeMemberSubtitle {
  font-family: 'DIN Condensed Bold';
  line-height: 1.0;
}

.activeMemberName {
  font-size: 15vh;
  text-align: left;
  margin: 0;
  text-transform: uppercase;
}

.activeMemberContact {
  text-align: right;
  font-size: 20px;
  margin: 0;
  margin-top: -30px;
}

.activeMemberSubtitle {
  text-transform: uppercase;
  color: #FC3027;
  text-align: left;
  font-size: 24px;
  margin: 0;
}

.activeMemberHeaderRight svg {
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.activeMemberHeaderRight svg text {
  stroke: #fff;
  fill: none;
  stroke-width: 1px;
  text-transform: uppercase;
  font-size: 15vh;
}

.activeMemberBioGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.activeMemberBioGrid p {
  text-align: left;
}

.aboutNav {
  position: fixed;
  display: flex;
  transform: rotate(-90deg) translate(-50%, 0);
  top: 50%;
  left: 0;
  transform-origin: top left;
}

.aboutNav li {
  list-style-type: none;
}

.aboutNav a {
  display: inline-block;
  padding: 10px 20px;
  background: #fff;
  color: #FC3027;
  text-decoration: none;
  text-transform: uppercase;
}

.aboutNav a.active {
  color: #fff;
  background: #FC3027;
}

.galleryInner {
  width: 100%;
  overflow: hidden;
  padding: 3% 0;
}

.galleryStage {
  display: flex;
  gap: 20px;
  position: relative;
  transition: 0.25s left;
}

.galleryItem {
  position: relative;
  height: 25vh;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}

.galleryItemImage {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.team, .about, .services {
  margin-left: 40px;
}

.about {
  padding: 2% 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact {
  display: flex;
  gap: 20px;
  align-items: center;
}

.contactLeftPanel {
  flex: 3;
  text-align: left;
  padding: 3%;
}

.contactRightPanel {
  flex: 5;
  padding: 3%;
}

.contactFormGrid {
  display: grid;
  row-gap: 40px;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 40px;
}

.contact form textarea {
  width: 100%;
  height: 20vh;
  margin-bottom: 20px;
}

input, textarea, select {
  border: none;
  border-bottom: 2px solid #FC3027;
  background: none;
  color: #fff;
  font-family: 'din-2014';
  font-size: 16px;
  line-height: 1.4;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

.contact form button {
  font-family: 'din-2014';
  text-transform: uppercase;
  float: right;
  padding: 10px 20px;
  color: #fff;
  font-size: 16px;
  background: #FC3027;
  border: none;
}

::placeholder {
  color: #fffc;
}

.contactRow {
  display: flex;
  margin-bottom: 30px;
}

.contactRow i {
  flex: 1;
  font-size: 24px;
  color: #FC3027;
}

.contactRow h3 {
  flex: 10;
  margin: 0;
}

.contactContent {
  margin-bottom: 40px;
}

.contact h1 {
  font-family: 'DIN Condensed Bold';
  text-transform: uppercase;
  font-size: 10vh;
  line-height: 1.0;
  margin: 0;
}

.aboutContent {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  text-align: left;
  gap: 20px;
  flex: 1;
}

.aboutRow {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  align-items: middle;
}

.aboutRow p {
  margin: 0;
}

.aboutRow .galleryInner {
  padding: 0;
  flex: 1;
}

.streetArtsImageWrap {
  padding-right: 5%;
  flex: 1;
  align-items: flex-end;
  display: flex;
}

.quoteImageWrap {
  flex: 1;
  align-items: center;
}

.streetArtsImageWrap img, .quoteImageWrap img {
  width: auto;
  max-height: 30vh;
}

nav ul {
  flex-grow: 1;
  justify-content: flex-end;
  gap: 20px;
}

nav {
  padding: 20px 3%;
  z-index: 1100;
}

nav ul a, nav ul li {
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
}

nav ul a li::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  width: 0px;
  height: 0px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transition: 0.25s all;
  transform-origin: 50% 50%;
}

nav ul a.active li::after {
  background: #FC3027;
  width: 5px;
  height: 5px;
  transition: 0.25s all;
}

.loader {
  font-size: 120px;
  font-family: 'DIN Condensed Bold';
  pointer-events: auto;
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: #fc3027;
  z-index: 1000;
  transition: 0.5s all;
}

.loader.active {
  left: 0;
}

.loader.loaded {
  left: 100%;
}

.caseStudySliderStage {
  display: flex;
  position: absolute;
  transition: 0.25s left;
}

.caseStudySlide {
  position: relative;
  flex: 1;
  width: 100vw;
  height: 100vh;
}

.home {}

.homeInner {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}

.caseStudySlideImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  z-index: -1;
  border-top: 3px solid #FC3027;
}

.caseStudyMeta {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  width: 50%;
  padding: 5%;
  padding-bottom: 10%;
  box-sizing: border-box;
}

.caseStudyActions {
  display: flex;
}

.caseStudyProgressBar {
  background: #fff;
  flex: 1;
}

.caseStudyProgressBarFiller {
  background: #FC3027;
  height: 3px;
  transition: 0.25s width;
}

.caseStudySliderControls {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 5%;
  height: 10vw;
  z-index: 20;
  width: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 20px;
}

.aboutSliderControls {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 0;
  padding-right: 10px;
  box-sizing: border-box;
}

.sliderButton {
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 16px;
  color: #fff;
  border: 1px solid #fff;
  background: none;
  position: relative;
  transition: 0.25s all;
  cursor: pointer;
}

.sliderButton:hover {
  color: #FC3027;
  border-color: #FC3027;
}

.sliderButton i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.activeCaseStudyNumber {
  font-family: 'DIN Condensed Bold';
  font-size: 48px;
  margin-bottom: -12px;
}

.button {
  padding: 10px 20px;
  border: 1px solid;
  margin-right: 10px;
  text-decoration: none;
  text-transform: uppercase;
}

.button.primary {
  background: #FC3027;
  color: #fff;
  border-color: #fc3027;
}

.caseStudySlide::after {
  content: '';
  position: absolute;
  left: 0;
  height: 50%;
  width: 100%;
  bottom: 0;
  background: linear-gradient(to top, black, transparent);
  z-index: 1;
  background-blend-mode: multiply;
  pointer-events: none;
}

.caseStudyTitle {
  font-family: 'DIN Condensed Bold';
  text-transform: uppercase;
  margin: 0;
  font-size: 80px;
}

.caseStudyDescription {
  margin: 0;
  margin-bottom: 20px;
}

.caseStudySubtitle {
  font-family: 'DIN Alternate Bold';
  text-transform: uppercase;
  font-size: 18px;
}

.caseStudySliderNav {
  z-index: 10000;
  position: absolute;
  width: 60%;
  left: 50%;
  bottom: 10%;
  height: 14vw;
  overflow: hidden;
}

.caseStudySliderNavStage {
  display: flex;
  position: absolute;
  top: 0;
  gap: 20px;
  height: 14vw;
  justify-content: center;
  align-items: stretch;
  transition: 0.25s left;
}

.caseStudySliderNavStage>div {
  flex: 1;
  cursor: pointer;
  box-shadow: 0 0 20px 0 #0004;
}

.caseStudySliderNavStage>div img {
  width: 100%;
}

.currentService {
  display: flex;
  gap: 20px;
  height: 100%;
}

.currentServiceLeft {
  display: flex;
  flex: 1;
  position: relative;
}

.currentServiceLeftInner {
  flex: 1;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.currentServiceContent {
  flex: 1;
  text-align: left;
  padding: 5%;
  box-sizing: border-box;
  position: relative;
  margin-top: 6vh;
}

.currentServiceLeft>p.servicesTitle {
  position: absolute;
  top: 0;
  right: 0;
  text-stroke: 1px #FC3027;
  -webkit-text-stroke: 1px #FC3027;
  font-size: 10vw;
  line-height: 1.0;
  padding-block-start: 0;
  padding-block-end: 0;
  margin: 0;
  color: transparent;
  font-family: DIN Condensed Bold;
}

.subserviceNav {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: flex-end;
}

.subserviceNavTitle {
  text-align: right;
  color: #FC3027;
  text-transform: uppercase;
  font-family: "Din Condensed Bold";
  font-size: 20px;
}

.currentServiceProgressBar {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  grid-area: span 1 / span 2;
}

.subserviceNavItem {
  flex: 1;
  cursor: pointer;
}

.subserviceNavItemInner {
  font-family: DIN Condensed Bold;
  color: transparent;
  text-stroke: 1px #fff;
  -webkit-text-stroke: 1px #fff;
  font-size: 8vh;
  line-height: 0.95;
  margin-block-start: 0;
  margin-block-end: 8px;
  text-align: right;
}

.subserviceNavItemInner.active {
  color: #fff;
}

.subserviceNavItem svg {
  height: 100%;
  width: 100%;
}

.currentSubservice {
  flex: 1;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.subservice {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 10px;
  flex: 1;
  position: relative;
}

.subserviceGallery {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 20vw;
  flex: 1;
  max-width: 13vw;
}

.subserviceGallery[data-items="3"] div:first-child {
  grid-area: span 1 / span 2;
  padding-bottom: 50%;
}

.subserviceGallery[data-items="3"] .subserviceGalleryItem {
  transform: none;
}

.subserviceStage {
  display: flex;
  position: absolute;
  transition: 0.25s all;
  top: 50%;
  transform: translateY(-50%);
}

.subserviceDescription {
  flex: 1;
  text-align: left;
  padding: 0 5%;
  max-width: 27vw;
}

.subserviceCta {
  font-family: 'DIN Alternate';
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
}

.subserviceCta span {
  color: #fc3027;
}

.subserviceCta::after {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  background: #fc3027;
}

.subserviceTitle {
  font-family: DIN Condensed Bold;
  color: transparent;
  text-stroke: 1px #FC3027;
  -webkit-text-stroke: 1px #FC3027;
  font-size: 16vh;
  line-height: 0.95;
  margin-block-start: 0;
  margin-block-end: 8px;
  text-align: right;
  position: absolute;
  z-index: -1;
  left: 50%;
  pointer-events: none;
  text-transform: uppercase;
  transform: translate(-50%, 0%) rotate(-90deg);
  max-width: 80vh;
}

.subserviceGalleryItem {
  padding-bottom: 100%;
  background-size: cover;
  background-position: center center;
}

.subserviceGalleryItem:nth-child(even) {
  transform: translateY(30px);
}

.notFound h1 {
  font-family: DIN Condensed Bold;
  color: transparent;
  text-stroke: 1px #FC3027;
  -webkit-text-stroke: 1px #FC3027;
  font-size: 12vh;
  line-height: 1.0;
  margin: 0;
}

.notFound h2  {
  font-family: DIN Condensed Bold;
  font-size: 6vh;
  line-height: 1.0;
  margin: 0;
}
.notFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.teamNavIndicatorWrap {
  position: relative;
}

.caseStudies {
  display: flex;
}
.caseStudiesTitle {
  flex: 1;
  position: relative;
}
.caseStudies .teamNav {
  flex: 1;
}
.caseStudiesTitle h1 {
  font-family: DIN Condensed Bold;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  font-size: 12vh;
  color: transparent;
  text-stroke: 1px #FC3027;
  -webkit-text-stroke: 1px #FC3027;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  margin: 0;
}
.currentCaseStudy {
  flex: 8;
}
.caseStudyContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: left;
  gap: 20px;
  padding-right: 10%;
}
.teamNav {
  padding-bottom: 5%;
}
.teamNav .sliderButton {
  margin-bottom: 20px;
}
.caseStudies .caseStudyTitle {
  line-height: 1.0;
  margin: 0;
  margin-top: 10px;
  font-size: 7vh;
}
.hamburgerTrigger {
  display: none;
}
.mobileOnly {
  display: none;
}
.geekG {
  width: 16px;
  height: 16px;
}
.geekLink {
  position: relative;

}

.galleryItemLightbox {
  position: fixed;
  z-index: 10000000000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.galleryItemLightbox:not(.active) {
  opacity: 0;
  pointer-events: none;
}
.closeLightboxButton {
  display: block;
  color: #fff;
  z-index: 100000000;
  background: none;
  border: 1px solid #fff;
  font-size: 22px;
  line-height: 44px;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.closeLightboxButton span{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.portfolio {
  display: flex;
}
.portfolioTitle {
  font-family: DIN Condensed Bold;
  text-transform: uppercase;
  transform: rotate(180deg);
  transform-origin: 50% 50%;
  font-size: 12vh;
  color: transparent;
  text-stroke: 1px #fff;
  -webkit-text-stroke: 1px #fff;
  white-space: nowrap;

  text-align: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin: 0;
  flex: 1;
}
.portfolioTitle h1 {
  margin: 0;
}
.portfolioContainer {
  flex: 9;
  position: relative;
  overflow: hidden;
}
.portfolioStage {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: dense column;
  height: 100%;
  position: absolute;
  transition: 0.25s left;
}
.portfolioItemWrap {
  display: flex;
  align-items: stretch;
  padding: 10px;
}
.portfolioItem {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-width: 15vw;
  width: 100%;
}
.contactEmail a,
.contactPhone a {
  text-decoration: none;
}
.scroll-controller {
  display: flex;
  gap: 30px;
  max-height: 20px;
}
.scroll-controller > p {
  height: 100%;
  margin: 0;
}
.scroll-controller img {
  max-width: 70px;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .mobileOnly {
    display: block;
  }
  .caseStudyMap {
    display: none !important;
  }
  .map .caseStudyMap {
    display: block !important;

  }
  .map .caseStudyMapBody {
    height: 100vh !important;
    position: relative;
  }
  .withMap .headerNav {
    margin-right: 0;
  }
  body {
    height: auto;
    max-width: 100vw;
    overflow-y: scroll;
  }
  .home .caseStudyMeta,
  .home .caseStudySliderControls {
    width: 100%;
  }
  .home .caseStudyMeta {
    padding-bottom: 3%;
    bottom: 80px;
    
  }
  .home .caseStudySliderControls {
    bottom: 20px;
  }
  .home .caseStudySliderNav {
    display: none;
  }
  .headerNav {
    position: fixed;
    background: #333;
    height: 100vh;
    flex-direction: column;
    right: -100%;
    transition: 0.25s all;
    top: 0;
    margin-top: 3px;
    width: 70%;
    padding-left: 0;
    align-items: flex-end;
    justify-content: start;
    padding-top: 28%;
    padding-right: 3%;
    font-family: "DIN Condensed Bold";
    font-size: 6vh;
    gap: 0;
    box-shadow: 0 0 0 0 #0002;
    box-sizing: border-box;
  }
  .headerNav .active li::after {
    display: none;
  }
  .headerNav.active {
    right: 0;
    box-shadow: 0 0 100px 0 #0002;
  }
  nav {
    justify-content: space-between;
  }
  .hamburgerTrigger {
    display: block;
    color: #fff;
    z-index: 10000;
    background: none;
    border: 1px solid #fff;
    font-size: 22px;
    line-height: 44px;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    position: relative;
  }
  .hamburgerTrigger span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .aboutNav {
    transform: none;
    position: static;
    width: 100%;
    margin-bottom: 20px;
  }
  .aboutNav a {
    flex: 1;
  }
  .about,
  .services,
  .team,
  .contact {
    padding: 0;
    margin: 0;
    flex-direction: column;
    align-items: stretch;
  }
  .teamNav {
    flex-direction: row;
    height: auto;
  }
  .teamNavIndicator {
    height: 40px;
    width: 40px;
    margin: auto;
    flex: none;
  }
  .aboutRow {
    flex-direction: column;
    padding: 10px;
  }
  .aboutRow img {
    width: 100%;
    height: auto;
  }
  .currentService {
    flex-direction: column;
  }
  .subserviceNav,
  .currentServiceProgressBar {
    display: none;
  }
  .currentServiceLeftInner {
    display: block;
  }
  .currentServiceLeft {
    flex: none;
    height: auto;
  }
  .subserviceStage {
    width: 100% !important;
    flex-direction: column;
    position: static;
    left: 0;
    transform: none;
  }
  .subserviceGallery {
    width: 100%;
  }
  .subservice {
    flex-direction: column;
    margin-bottom: 10%;
  }
  .subserviceTitle {
    position: static;
    transform: none;
    text-align: center;
    font-size: 20vw;
    margin-bottom: 0;
  }
  .subserviceDescription {
    margin-top: -40px;
  }
  .caseStudies {
    flex-direction: column;
  }
  .caseStudyContent {
    display: block;
    columns: 1;
    padding-bottom: 15%;
  }
  .caseStudiesTitle {
    overflow: hidden;
  }
  .caseStudiesTitle h1 {
    transform: none;
    writing-mode: horizontal-tb;
    left: 0;
    text-align: center;
    position: static;
  }
  .activeMemberBioGrid {
    display: block;
    columns: 2;
    order: 2;
    padding-bottom: 15%;
  }
  .activeMemberBioGrid p {
    margin-block-start: 0;
  }
  .activeMemberGallery {
    order: 1;
  }
  .activeMember {
    display: flex;
    flex-direction: column;
  }
  .activeMemberHeader {
    display: grid;
    grid-template-columns: auto auto;
  }
  .activeMemberHeaderRight {
    grid-area: span 1 / span 2;
  }
  .activeMemberHeaderLeft {
    max-width: 100vw;
  }
  h1.activeMemberName {
    font-size: 30vw;
  }
  .headshot {
    width: 100%;
    height: auto;
    display: none;
  }
  .headshotWrap {
    height: auto;
  }
  .aboutContent {
    grid-template-columns: 1fr !important;
    grid-auto-flow: row;
    padding-bottom: 15%;
  }
  .caseStudySlide {
    overflow: hidden;
  }
  .caseStudySlide .caseStudyTitle {
    line-height: 0.8;
    font-size: 14vw;
  }
  .caseStudySlide .caseStudySubtitle {
    margin-bottom: 10px;
    font-size: 4vw;
  }
  .aboutNav a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobileOnly .caseStudySubtitle,
  .mobileOnly .caseStudyTitle {
    text-align: left;
  }
  .caseStudyContent .caseStudyTitle,
  .caseStudyContent .caseStudySubtitle {
    display: none; 
  }
  nav {
    padding: 5px 12px;
  }
  .teamNav .sliderButton {
    margin-bottom: 0;
  }
  .teamNav .sliderButton.last {
    margin-right: 5px;
  }
  .teamNav .sliderButton.next {
    margin-right: 5px;
  }
  .teamNav .sliderButton.last,
  .teamNav .sliderButton.next {
    transform: rotate(-90deg);
  }
  .activeMemberHeaderRight svg text {
    font-size: 6vh;
  }
  .headerNav.active .headerOverlay {
    position: absolute;
    right: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
  }
  .headshotWrap, 
  .activeMemberLocation,
  .activeMemberHeaderRight,
  .caseStudiesTitle {
    display: none;
  }
  .teamNavIndicator:not(.active) {
    transform: scale(0.65);
  }
  .currentServiceLeft > p.servicesTitle {
    right: auto !important;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10vh;
  }
  .portfolio {
    display: block;
  }
  .portfolioContainer {
    position: static;
    display: flex;
    flex-direction: column;
    gap: 5px; 
  }
  .portfolioStage {
    position: static;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-auto-flow: dense;
  }
  .portfolioItem {
    min-height: 15vh;
    width: 100%;
  }
  .portfolioTitle {
    transform: none;
    writing-mode: horizontal-tb;
    font-size: 12vw;
  }
  .App > div:not(.home) {
    padding: 0 8px;
  }
  .contactFormGrid {
    grid-template-columns: 1fr;
  }
  .button {
    padding: 4px 8px;
  }
  .caseStudySlide {
    height: calc(100vh - calc(20px + 10vh));
  }
  .homeInner {
    background: #000;
  }
}

@media only screen and (max-width: 1679px) {
  .activeMemberBioGrid {
    grid-template-columns: 1fr 1fr 1fr;
    padding-right: 30px;
  }
  .aboutContent {
    flex: 2;
    gap: 10px;
  }
  .aboutRow .galleryInner {
    flex: 2;
  }
  .currentCaseStudy .galleryInner {
    padding: 0;
  }
  .streetArtsImageWrap {
    display: none;
  }
  .aboutContent {
    grid-template-rows: 1fr;
  }
}

@media only screen and (max-width: 1439px) {
  .map .caseStudyMapBody {
    height: 100vh;
    position: relative;
  }
  .streetArtsImageWrap {
    display: none;
  }
  .App {
    height: auto !important;
  }
  .currentServiceLeftInner {
    display: block;
  }
  .currentServiceLeft {
    height: auto;
  }
}
.slideout-bar {
  position: absolute;
  top: 50%;
  right: 100%;
  background: #fff;
  z-index: 100;
  transform: translateX(0px) translateY(-50%);
  transition: all .25s;
  overflow: hidden;
  box-shadow: 0 0 90px 10px #000;
  background: #181818;
  padding: 1% 0;
  border-top: solid 3px #FC3027;
  height: 400px;
  width: 400px;
}
.modal-content-container * {
  display: none;
  opacity: 0;
  transition: all .5s;
  width: inherit;
  margin: 0;
}
.modal-content-container p,
.modal-content-container h3 {
  width: 90% !important;
}
.slideout-bar.open {
  height: max-content !important;
}
.slideout-bar.open .modal-content-container * {
  display: block;
  opacity: 1;
}
.modal-content-container {
  height: max-content;
  width: inherit;
  display: flex;
  gap: 1vw;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-height: 500px;
}
.modal-content-container img {
  height: max-content;
}
#close-modal {
  color: #FC3027;
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: none;
  box-shadow: none;
  font-size: 20px;
  cursor: pointer;
}
@media only screen and (max-width: 500px) {
  .slideout-bar {
    width: 100%;
  }
  .subserviceGallery {
    display: flex;
    max-width: none;
    width: 100%;
  }
  .subserviceDescription {
    max-width: none;
  }
  .subserviceGalleryItem {
    padding: 0;
    width: 200px;
    height: 200px;
  }
}